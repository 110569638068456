const copys = {
  welcomeMessage: "¡Hola! Ingresa tu número celular",
  inputPhone: "Número celular",
  labelBtnContinuar: "Continuar",
  labelBtnLogin: "Iniciar sesión",
  buttonCreateAccount: "Crear cuenta",
  dontHaveAccount: "¿No tienes cuenta?",
  labelRegister: "Únete a Descuentón",
  labelIngressOTPDetail:
    "Escribe el código que recibiste en tu dispositivo móvil",
  labelResendOTP: "Reenviar código",
  labelDisclaimerResendOTP:
    "*¿Aún no llega? Te podemos reenviar hasta 2 códigos cada 24 h",
  labelRegisterScreenTitle: "¡Ya casi terminas!",
  labelRegisterScreenSubTitle: "Escribe la información solicitada",
  toastCloseButton: "Cerrar",
  footerCopyright: `© ${new Date().getFullYear()} Descuentón`,
  headerLoginButton: "Iniciar sesión",
  headerProfileButton: "Perfil",
  detailCardOnHoldTitle: "Disponible en ?? horas",
  detailCardOnHoldText: "Descuida, tienes otras opciones",
  buttonBack: "Regresar",
  titleProfileScreen: "Perfil",
  profileHeaderName: "Mi nombre es",
  profileHeaderYear: "Año en que nací",
  profileHeaderGender: "Me identifico como",
  buttonLogout: "Cerrar sesión",
  inputPhoneErrorMessage: "Tu número debe tener 10 dígitos",
  inputAgeErrorMessage: "Debes tener más de 18 años",
  inputAgeErrorMessage100Years:
    "¡Vaya! ¿Tienes más de 100 años? Verifica tu edad",
  labelAgeRadio: "¿Cómo te identificas?",
  toastErrorMessageDefault: "Hay un fallo temporal, intenta más tarde",
  screenHomeTitle: "Canjea, ahorra y gana",
  expiredCardTitle: "Promoción vencida",
  expiredCardText: "Descuida, tenemos más opciones para ti",
  buttonSeeMoreCoupons: "Explorar promociones",
  instructionsCard: "Presenta el código en el mostrador",
  detailCardDisabledTitle: "La promoción ya fue canjeada",
  detailCardDisabledText: "Descuida, hay más opciones para ti",
  loadingHomeText1: "¡Hola??!",
  loadingHomeText2: "Estás a punto de tener las mejores promociones",
  modalLogoutText: "¿Deseas cerrar sesión?",
  modalLogoutButtonOk: "Sí, cerrar sesión",
  modalLogoutButtonCancel: "No, regresar",
  loadingSpinner: "Cargando",
  loadingPromoText: `¡Estamos generando tu promoción!`,
  detailCardNotLogged: "Debes iniciar sesión para canjear tu cupón",
  backButtonGoHome: "Mostrar promociones",
  buttonAvailable24h: "Disponible en ",
  labelNameInput: "¿Cómo te llamas?",
  labelYearBirthInput: "¿En qué año naciste?",
  buttonBannerAvailable: "Cupón disponible",
  buttonBannerDisabled: "Canjes agotados",
  couponCounter: "cupones canjeados",
  generalConditionsPromo: "Condiciones generales",
  buttonShowMore: "Mostrar más",
  buttonShowLess: "Mostrar menos",
  toastRegisterSuccessful: "Creaste tu cuenta",
  toastLoginSuccessful: "Iniciaste sesión",
  toastAcceptNewTermsFailed:
    "Ocurrió un error inesperado al intentar aceptar los nuevos términos y condiciones",
  toastCantRedeemInCurrentStore:
    "No puedes canjear esta promoción en esa tienda",
  homeSlogan: "Accede a los mejores descuentos de tu zona",
  inputLocationPlaceholder: "Ingresa una dirección o colonia...",
  headerWhenTypingLocation: "Agregar nueva dirección",
  promptPasteCopiedAddress: "Pegar dirección copiada",
  store: "Tiendas",
  store_available: "Buscando Tiendas disponibles",
  promotions_available: "Estas buscando promociones disponibles",
  disclaimerCalls:
    "Tu número está seguro con nosotros. No realizamos llamadas por ningún motivo.",
  labelStoresNotFound:"No hemos encontrado tiendas cerca a ti en",
  labelFoodStoresNotFound:"No hemos encontrado Centros de canje de alimentos cerca a ti en",
  labelFoodStoresAndFoodNotFound:"No hemos encontrado tiendas ni Centros de canje de alimentos cerca a ti en",
  labelFavoriteNotFound:"No hemos encontrado Centros de canje en tus favoritas cerca a ti en",
};

export const termsAndPrivacy = {
  labelDisclaimerTerms: [
    "Al registrate aceptas los ",
    "términos, condiciones",
    " y ",
    "políticas de privacidad",
    " de Descuentón",
  ],
  termsFooterLabel: "Términos y condiciones",
  privacyFooterLabel: "Políticas de privacidad",
  modal: {
    text: [
      "Hemos actualizado nuestros",
      "Términos y condiciones.",
      "Acéptalos y accede a los mejores descuentos.",
    ],
    buttonAccept: "Aceptar y continuar",
    buttonReject: "No, cerrar sesión",
  },
};

export default copys;
