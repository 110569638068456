import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import copys from "../utils/copys";
import { ReactComponent as LocationSVG } from "../assets/ilustraciones/location.svg";
import { ReactComponent as MapHouseSVG } from "../assets/ilustraciones/map_in_house.svg";
import { ReactComponent as FavoritesIcon } from "../assets/ilustraciones/favoritesShop.svg";
import { ReactComponent as NotFavoriteIcon } from "../assets/ilustraciones/notFavorite.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/ilustraciones/ic_ arrow_right.svg";
import { useLocationContext } from "../hooks/useLocationContext";
import { Store } from "../models/location";
import ModalNoStores from "../components/ModalNoStores";

interface ScreenStoreAvailableProps {
  stores: Store[],
  setStores: React.Dispatch<React.SetStateAction<Store[]>>,
  loading:boolean,
}

export const ScreenStoreAvailable = ({ stores, setStores, loading }: ScreenStoreAvailableProps) => {

  const { selectedStoreFilters, locationUserData, setHomePagePreviousState, } = useLocationContext();
  const { latitude, longitude, addressSelected }: any = locationUserData.location;

  const navigate = useNavigate();

  const { REACT_APP_AUTH_URL } = process.env;


  const handleFavoriteAction = async (
    shopkeeper_id: string,
    isFavorite: boolean
  ) => {
    if (!stores) return;

    const store = stores.find(
      (store) => store.id === shopkeeper_id
    );
    if (!store) return;

    try {
      const storeData: any = localStorage.getItem("auth");
      const userData = JSON.parse(storeData);
      const user_id = userData.user.id;

      if (isFavorite) {
        const response: any = await axios.delete(
          `${REACT_APP_AUTH_URL}/v1/consumer/favorites/${user_id}/${store.shopkeeper_id}`,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );

        if (response.data.successful) {
          const updatedStores = stores.map((store) =>
            store.id === shopkeeper_id
              ? { ...store, isFavorite: !isFavorite }
              : store
          );

          setStores(updatedStores);
          return;
        }
      }

      const response: any = await axios.post(
        `${REACT_APP_AUTH_URL}/v1/consumer/favorites`,
        {
          shopkeeper_id: store.shopkeeper_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedStores = stores.map((store) =>
          store.id === shopkeeper_id
            ? { ...store, isFavorite: !isFavorite }
            : store
        );

        setStores(updatedStores);
      }
    } catch (error) { }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 self-stretch">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#FFB100"
          ariaLabel="tail-spin-loading"
          radius="1"
          strokeWidth={4}
          wrapperStyle={{}}
          wrapperClass=""
        />
        <div className="font-roboto text-sm font-normal text-text_strong">
          {copys.store_available}
        </div>
      </div>
    );
  }

  if (!stores || stores === null)
    return (
      <div>
        <div className="location-container">
          <h2 className="color:#070561">Por favor, ingresa otra dirección</h2>
          <div className="location-message">
            <div className="icon">
              <LocationSVG />{" "}
            </div>
            <p className="message-error-store">
              No hemos encontrado tiendas cerca a ti en{" "}
              {addressSelected.fullAddress}
            </p>
          </div>
          <button id="buttonAuthorizeGeolocation" className="authorize-button">
            <MapHouseSVG /> Autorizar ubicación
          </button>
        </div>
      </div>
    );

  const goToDetailShop = (shopkeeper_id: string) => {

    setHomePagePreviousState({
      storeView: 'list',
      selectedStoreFilters,
    })
    navigate(
      `/store/details/${shopkeeper_id}?lat=${latitude}&lng=${longitude}&label=${label}`
    );
  };

  const label = selectedStoreFilters[0] === "TRADE"
    ? "Centros de canje"
    : selectedStoreFilters[0] === "FAVORITE"
      ? "Favoritas"
      : selectedStoreFilters[0] === "ONPREMISE"
        ? "Alimentos"
        : "Alimentos";

  return (
    <>
      {stores.length < 1 || stores === null ? (
        <div className="relative w-screen mt-10">
          <ModalNoStores/>
        </div>
      ) : (
        stores.map(
          ({
            id,
            shopkeeper_id,
            full_address,
            distance,
            store_name,
            isFavorite,
            available_promotions,
          }) => (
            <div className="space" key={id}>
              <div className="card">
                <div className="distance-badge">A {distance} m</div>

                <div className="card-content">
                  <div className="title">
                    <h2>{store_name ? store_name : "Tienda"}</h2>
                    <div
                      id="buttonSetFavorite"
                      className={`favorites-icon ${isFavorite ? "active" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFavoriteAction(id, !!isFavorite);
                      }}
                    >
                      {isFavorite ? <FavoritesIcon /> : <NotFavoriteIcon />}
                    </div>
                  </div>
                  <p className="address">{full_address}</p>
                  <p className="subtext">{label}</p>
                  <p className="discounts">
                    {available_promotions} Descuentos activos
                  </p>
                </div>
                <div
                  id="buttonGoToStoreDetail"
                  className="arrow-icon"
                  onClick={() => goToDetailShop(shopkeeper_id)}
                >
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
          )
        )
      )}
    </>
  );
};
