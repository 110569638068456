import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import ChipButton from '../ChipButton';
import copys from '../../utils/copys';
import { localStorageTourName, promoStatus, routePaths } from '../../utils/constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { usePromoContext } from '../../hooks/usePromoContext';
import CountdownTimer from '../CountdownTimer';
import * as gtmService from '../../services/gtmService';
import { PromotionDetail, PromotionItem } from '../../models/promotions';
import { ReactComponent as ClockSvg } from '../../assets/ilustraciones/clock.svg';
import { MapPin } from 'lucide-react';
import { cn } from '../../utils/cn';

type CardProps = {
  promo: PromotionItem | PromotionDetail,
  isImageOnly?: boolean,
  status: number,
}

type ChipButtonLabelAndClass = {
  label: string | JSX.Element,
  class: string,
}

export default function Card({ promo, isImageOnly, status }: CardProps) {

  const [chipButtonLabelAndClass, setChipButtonLabelAndClass] = useState<ChipButtonLabelAndClass>({ label: copys.buttonBannerAvailable, class: 'bg-success' });
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { setPromoData } = usePromoContext();
  const parentChipButtonRef = useRef<HTMLDivElement | null>(null);


  const goToPromo = () => {
    if (isImageOnly || (!localStorage.getItem(localStorageTourName) && userData.isLogged)) return;

    gtmService.pushViewOrSelectPromotion(false, promo.id!, userData.isLogged, promo.description!)

    setPromoData({ promoId: promo.id! })
    if (userData.isLogged) {
      navigate(`/${routePaths.DETAIL.replace(':promoId', promo.id!)}`);
    } else {
      navigate('/' + routePaths.LOGIN);
    }
  }

  const changeChipToAvailable = () => {
    setChipButtonLabelAndClass({ label: copys.buttonBannerAvailable, class: 'bg-success' })
  }

  useEffect(() => {
    if (!isImageOnly) {
      gtmService.pushViewOrSelectPromotion(true, promo.id!, userData.isLogged, promo.description!)
    }

    if (userData.isLogged) {
      if (status === promoStatus.ONHOLD) {
        const chipButtonColorClass = 'bg-neutral_weak';
        const chipButtonlabel = <div className='flex items-center gap-1'><ClockSvg /> <CountdownTimer targetDate={promo.last_exchange_time!} onComplete={() => changeChipToAvailable()} /></div>;
        setChipButtonLabelAndClass({ label: chipButtonlabel, class: chipButtonColorClass })
      } else if (status === promoStatus.DISABLED) {
        const chipButtonColorClass = 'bg-neutral_weak';
        const chipButtonlabel = copys.buttonBannerDisabled;
        setChipButtonLabelAndClass({ label: chipButtonlabel, class: chipButtonColorClass })
      }
    }
  }, [])


  return (
    <div id={`card_${promo.id}`} className={`container sm:w-72 flex flex-col justify-center items-center relative`} onClick={goToPromo} >
      {promo?.banner_url && <img src={promo.banner_url} alt="banner" className={cn(`min-w-full `, status === promoStatus.DISABLED && ' grayscale', isImageOnly && 'h-48')} />}
      {
        !isImageOnly && (
          <>
            <div className='absolute right-0 bottom-0'>
              <div ref={parentChipButtonRef} className="px-5 py-3" >
                <ChipButton id={promo.id} label={chipButtonLabelAndClass.label} colorClass={chipButtonLabelAndClass.class} />
              </div>
            </div>

            {
              promo.distance && (
                <div id='badgeDistance' className={cn(status !== promoStatus.DISABLED ? 'bg-[#FCCC0A]' : 'bg-[#CDD4D9]', 'rounded-[7.5px] px-[7.5px] py-[5px] flex items-center gap-[5px] absolute top-3 right-5')} >
                  <MapPin color='#100E9D' size={16} />
                  <div className='font-semibold text-[#141938] text-[12.5px]'>
                    A {promo.distance} m
                  </div>
                </div>
              )
            }
          </>
        )
      }
    </div>
  )
}
