import { Logo } from '../../components/Logo'
import { useAuthContext } from '../../hooks/useAuthContext'
import copys from '../../utils/copys';
import IconArrowBack from '../../components/icons/IconArrowBack';
import { useNavigate } from 'react-router-dom';

type HeaderProps = {
  customHeader?: string;
  backButtonCallback?: () => void;
}
export default function Header({ customHeader, backButtonCallback }: HeaderProps) {

  const { userData } = useAuthContext();
  const navigate = useNavigate()

  if (customHeader) {
    return (
      <header className='flex gap-6 p-6 items-center'>
        <div id='buttonBackHeader' onClick={backButtonCallback ? backButtonCallback : () => navigate(-1)}>
          <IconArrowBack />
        </div>
        <div className='text-[#070561] font-semibold text-xl font-libre-franklin'>
          {customHeader}
        </div>
      </header>
    )
  } else {
    return (
      <header id='appHeader' className='flex bg-[#D4E5FF] items-center px-4 gap-8 py-2'>
        <Logo width={65} />
        <div className='text-[#070561] font-semibold text-2xl font-libre-franklin'>
          {
            userData.isLogged ? 'Hola ' + userData.user?.name.split(' ')[0] : copys.screenHomeTitle
          }
        </div>
      </header>
    )
  }
}
