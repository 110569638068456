import React from "react";
import IconArrowBack from "./icons/IconArrowBack";
import copys from "../utils/copys";
import { useNavigate } from "react-router-dom";

type ButtonBackProps = {
  customPage?: string;
  customLabel?: string;
};

const ButtonBack = ({ customPage, customLabel }: ButtonBackProps) => {
  const navigate = useNavigate();
  return (
    <>
      {customLabel === "Tiendas" ||
      customLabel === "Favoritas" ||
      customLabel === "Alimentos" ? (
        <button
          id="buttonBack"
          className="flex items-center py-2 px-4 gap-4 text-[#070561] text-center font-roboto text-base font-semibold"
          style={{
            fontSize: "22px",
          }}
          onClick={() => (customPage ? navigate(customPage) : navigate(-1))}
        >
          <IconArrowBack />
          {customLabel ? customLabel : copys.buttonBack}
        </button>
      ) : (
        <button
          id="buttonBack"
          className="flex items-center py-2 px-4 gap-1 text-text_strong text-center font-roboto text-base font-semibold"
          onClick={() => (customPage ? navigate(customPage) : navigate(-1))}
        >
          <IconArrowBack />
          {customLabel ? customLabel : copys.buttonBack}
        </button>
      )}
    </>
  );
};

export default React.memo(ButtonBack);
