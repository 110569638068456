import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as UserCircle } from '../../assets/ilustraciones/user_circle.svg'
import Logo from '../../assets/img/logo.png'
import IconTicket from '../../components/icons/IconTicket'
import { cn } from '../../utils/cn'
import { useAuthContext } from '../../hooks/useAuthContext'
import { routePaths } from '../../utils/constants'
import { useLocationContext } from '../../hooks/useLocationContext'
import { TabsOptions } from '../../components/screenHome/Tabs'

type FooterProps = {
  setSelectedTab: React.Dispatch<React.SetStateAction<TabsOptions>>,
}

export default function Footer({ setSelectedTab }: FooterProps) {

  const location = useLocation();
  const navigate = useNavigate();
  const { userData, setUserData } = useAuthContext();
  const { locationUserData } = useLocationContext();
  const isHomePage = location.pathname === '/' || location.pathname === '/home';

  const handleProfileClick = () => {
    if (userData.isLogged) {
      if (isHomePage) {
        setUserData({ ...userData, navigatedFromHome: true })
      }
      navigate('/' + routePaths.PROFILE)
    } else {
      navigate('/' + routePaths.LOGIN);
    }
  }

  const handleStoreClick = () => {
    if (locationUserData.locationType !== 'none') {
      setSelectedTab('stores');
    }
  }

  return (
    <div className="flex bg-white rounded-t-[40px] w-full h-28 pt-5 px-7 pb-4 gap-6 justify-between fixed bottom-0">
      <div id='buttonProfileFooter' className='flex flex-col gap-2 justify-center items-center w-[92px] cursor-pointer' onClick={handleProfileClick}>
        <UserCircle />
        <div className='text-[#070561] font-roboto text-xs text-center'>Perfil</div>
      </div>
      <div id='buttonHome' className="flex flex-col gap-2 items-center w-[92px] cursor-pointer" onClick={()=>setSelectedTab('promotions')}>
        <img className='drop-shadow-[0_4px_4px_#00000082]' src={Logo} alt="Logo descuentón" width={54} />
        <div className='text-[#070561] font-roboto text-xs text-center'>Inicio</div>
      </div>
      <div id='buttonStoresFooter' className='flex flex-col gap-2 justify-center items-center w-[92px] cursor-pointer' onClick={handleStoreClick}>
        <IconTicket fill={locationUserData.locationType !== 'none' ? '#070561' : '#95A2AD'} />
        <div className={cn('font-roboto text-xs text-center', locationUserData.locationType !== 'none' ? 'text-[#070561]' : 'text-[#95A2AD]')}>Centros de canje</div>
      </div>
    </div>
  )
}
